import React from "react";
import Item from "./IssueItem";
import { Link } from "react-router-dom";

export default function currentIsuue(props) {
  return (
    <div>
      <div
        className="section inner_page_banner"
        style={{ backgroundColor: "#ff8436" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title">
                <h3>Current Issue</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="full">
                <div className="heading_main text_align_center">
                  <h2 style={{ fontSize: "225%" }}>
                    <span className="theme_color"></span>
                    {props.header1.title}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12" style={{ border: "1px solid" }}>
              <h4>Cover Page</h4>

              <p className="hide descript"></p>
              <div className="row icons-all">
                <div className="col-md-4 col-4"></div>
                <div className="col-md-4 col-4"></div>
                <div className="col-md-4 col-4">
                  <Link to={props.header1.coverPage} target="_blank">
                    View Cover Page
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={{ border: "1px solid" }}>
              <h4>About the Journal</h4>

              <p className="hide descript"></p>
              <p></p>

              <div className="row icons-all">
                <div className="col-md-4 col-4"></div>
                <div className="col-md-4 col-4"></div>
                <div className="col-md-4 col-4">
                  <Link
                    to="/img/Journal about us for cover.pdf"
                    target="_blank"
                  >
                    View About theJournal
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={{ border: "1px solid" }}>
              <div className="well">
                <h4>Table of Contents</h4>

                <p className="hide descript"></p>
                <p></p>

                <div className="row icons-all">
                  <div className="col-md-4 col-6"></div>
                  <div className="col-md-4 col-6"></div>

                  <div className="col-md-4 col-6">
                    <Link to={props.header1.tableOfContents} target="_blank">
                      View Table of Contents
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {props.items.map((item) => {
              return <Item items={item} />;
            })}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
