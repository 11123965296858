import React from 'react'

export default function FAQ() {
  return (
    <>
    <div className="section inner_page_banner" style={{backgroundColor:"#ff8436"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner_title">
                        <h3>FAQ's</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="section layout_padding about_bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">

                    <div className="full paddding_left_15" style={{textAlign: "justify"},{textJustify: "inter-word"}}>
                    <p>
                            
<b>Q. What areas of research are covered under the scope of the journal?</b><br/><br/>
A. The scope covers all researches related to holistic wellness that includes physical, mental, social, emotional, spiritual wellness of a human being leading to a higher Happiness Quotient. The Journal covers all types of research work in the field of Dietary practices / guidelines, Diet and Disease, Dietary Surveys, Clinical Nutritional Interventions, Public Health Nutrition, Nutrigenomics, Molecular Nutrition, Food Processing and Technology, New Product Development, Value Added Food Products, Nutritional Epidemiology, Weight Management, Organic & Agri-based Food Researches, Food Biotechnology & all relevant Health based studies. Lifestyle based researches including Yoga, Exercise, Herbal medicinal researches, et. al. are also included<br/>
<br/>
<b>Q. How to submit a manuscript ?</b><br/>
A.  e-mail it at contact@researchthrive.com in word / pdf format.<br/><br/>

<b>Q. Are there any charges to be submitted?</b><br/>
A.  At present, there are No charges.<br/><br/>

<b>Q.	Is there any consent required before submission?</b><br/>
A.	Yes, A consent form (mentioning authorship conflict, plagiarism or not submitted anywhere
else) given in authors guidelines needs to be submitted signed by the author with submission of the manuscript<br/><br/>

<b>Q.	Is it a peer reviewed journal?</b><br/>
A.  Yes - It's a peer reviewed journal with a double blind review process.<br/><br/>

<b>Q.	Is this journal indexed?</b><br/>
A.  As it a new journal, it has been registered in NISCAIR and has an ISSN for online version.    
    Right now UGC and Scopus indexing is under process.<br/><br/>

    <b>Q. Does it has print version or online version only?</b><br/>
A. It is an online journal only.<br/><br/>

<b>Q.	Is it published in languages other than English also?</b><br/>
A.  It is published in English only.<br/><br/>

<b>Q.	How to prepare a manuscript?</b><br/>
A. It should be in Arial font 12 with headings in 14 font size. Title in 16 font size. References in  APA format in font size 10. For more details, please refer to authors guidelines<br/><br/>

                        </p>
                    </div>

                </div>
                <div className="col-md-6">
                    <div className="full text_align_right_img">

                  </div>
                </div>
            </div>
        </div>
      </div>

    
        
    </>
  )
}
