import React from 'react'
import { Link} from "react-router-dom";
import EndPageBanner from './endPageBanner'


export default function () {
  return (
      <>
                  <div className="section inner_page_banner" style={{backgroundColor:"#ff8436"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner_title">
                        <h3>International Journal of Nutrition & Lifestyle</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="full text_align_right_img">
                        </div>
                    </div>
                    <div className="col-md-6 layout_padding">
                        <div className="full paddding_left_15">
                            <div className="heading_main text_align_left">
                            </div>
                        </div>
                        <div className="full paddding_left_15">
                            <p>The prime aim of the International journal of Nutrition &#38;; Lifestyle is to give platform to quality research work by eminent researches &#38; share their studies on a common platform devoted to Holistic Health &#38; Wellness.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="section layout_padding about_bg">
        <div className="section layout_padding about_bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="full paddding_left_15">
                            <div className="heading_main text_align_left">
                            <h2>About Us</h2>
                            </div>
                        </div>
                        <div className="full paddding_left_15" style={{"textAlign": "justify"}, {"textJustify": "inter-word"}}>
                            <p>
                                International journal of Nutrition &#38;; Lifestyle ensures the scrutinized publication of high-quality articles that are of current interest &#38; importance. Two independent review comments followed by the editor's decision will be considered to publish the article.
                                It’s a quarterly scientific research journal devoted to original articles based on experimental/ community/ clinical nutrition &#38; lifestyle related research or practices that contribute to holistic health &#38; wellness of human beings. Original Research articles, comprehensive Reviews, Case Reports, Short Communications etc. are invited for possible publication.<br/>
                                <b>Language of Journal:</b> English<br/>
                                <b>Starting Year:</b> 2021<br/>
                                <b>Format of Publication:</b> Online Only<br/>
                                <b>Frequency of Publication:</b> Peer-reviewed quarterly Online journal<br/>
                        </p>
                        </div>
                        <div className="full paddding_left_15">
                            <Link className="main_bt" to="/about" >Read More </Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="full text_align_right_img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <EndPageBanner/>
    </>

  )
}
