import React from 'react'
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import { Collapse } from 'bootstrap'


export default function IssueItem({items}) {
  var [toggle, setToggle] = useState(false);
  
  useEffect(() => {
      var myCollapse = document.getElementById(items.sNo)
      var bsCollapse = new Collapse(myCollapse, {toggle: false})
      toggle ? bsCollapse.show() : bsCollapse.hide()
  },[{items}])
  return (
    <div className="w-100 col-lg-12" style={{"border":"1px solid"}}>
        <div className="hide">{items.articleType}</div>

        <h4>{items.title}</h4>

        <p className="hide descript"></p>
        <div className="row icons-all">
            <div className="col-md-4 col-4">
                <div className="hide">{items.author}</div>
            </div>
            {/* <div className="col-md-4 col-4" style={{"align":"center"}}>

                <CollapseDemo items={items}/>
            </div>
         */}
         <div className="col-md-4 col-4" style={{"align":"center"}}>

              <button className="btn btn-primary" onClick={() => setToggle(toggle => !toggle)}>
              Abstract
              </button>
          </div>
         
            <div className="col-md-4 col-4 w-50">
                {/* <Link className="w-50" to={items.fullArticle} target="_blank">View Full Article</Link> */}
                {/* <Link to={`/article/${items.sNo}`}>View Full Article</Link> */}
                <Link to={`/article/${items.sNo}`} className="btn btn-link">View Full Article</Link>

            </div>
            {items.doi &&
            <div style={{"margin":"0px 20px 20px 15px"}}>
                <strong>DOI Number:</strong> <Link to={items.doi} target="_blank" rel="noopener noreferrer">{items.doi.slice(16)}</Link>
            </div>
        }



          
          <div className="collapse" id={items.sNo}>
            
          <div className="card card-body">{items.shortDesc}</div>
          
   
  </div>
        </div>
    </div>
  ) 
}
