import React from 'react'
import { Link } from "react-router-dom";

export default function editorialBoard() {
  return (
    
        <div>
            <div className="section inner_page_banner" style={{backgroundColor:"#ff8436"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner_title">
                        <h3>Editorial Board</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
            <div className="section layout_padding" >
                <div className="container">
                    <div className="row">


                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}} >
                                    Dr. Vineeta Bansal (Ph.D., R.D.) (Editor-in-Chief)<br/>
                                    Associate Professor - Clinical Nutrition and Dietetics<br/>
                                    Mahatma Gandhi Medical College &#38; Hospital,<br/>
                                    Sitapura Industrial Area, Jaipur-302022, Rajasthan, India.<br/>
                                    R.D. No. 562018<br/>
                                    Email ID: drvineetabansal@mgumst.org<br/>
                                    <a target="_blank" href="https://www.mgmch.org/departments/department-of-clinical-nutrition-dietetics"> Profile Link</a>

                                    </p>

                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}} >Dr. Ranjana Nagar (Patron)<br/>
                                    Professor (Retd.) - Nutritional Biochemistry<br/>
                                    Foods and Human Nutrition<br/>
                                    Department of Home Science<br/>
                                    University of Rajasthan,
                                    Jaipur, India<br/>
                                    Email ID: ranjananagar@yahoo.com
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Prof. Sheel Sharma (Editor)<br/>
                                    M.Sc. (Honours School), University Medalist,<br/>
                                    Ph. D (Punjab University, Chandigarh)<br/>
                                    Professor, Biochemistry &#38;amp; Human Nutrition,<br/>
                                    Department of Food Science and Nutrition,<br/>
                                    Banasthali Vidyapith, Banasthali-304022, India<br/>
                                    Email ID: sheelsharma@banasthali.in<br/>
                                    <a target="_blank" href="http://www.banasthali.org/banasthali/wcms/en/home/lower-menu/faculties/home-science/top1homescience/faculty/facultyaiidep.html" style={{color: "blue"}, {textDecoration:"underline"}} >Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Dr. Vipin Bhayana PhD, FCACB (Editor)<br/>
                                    Division Head, Clinical Biochemistry,<br/>
                                    Associate Professor, Western University<br/>
                                    Pathology and Laboratory Medicine,<br/>
                                    London Health Sciences Centre &#38; St. Joseph's Health Care,<br/>
                                    London, Ontario, Canada<br/>
                                    Email ID: vipin.bhayana@lhsc.on.ca / vbhayana@uwo.ca<br/>
                                    <a target="_blank" href="http://www.schulich.uwo.ca/biochem//people/faculty/Bhayana.html" style={{color: "blue"}, {textDecoration:"underline"}} >Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Prof. Jonathan Deutsch Ph.D., CHE, CRC (Editor)<br/>
                                    Professor and Director, Drexel Food Core Lab,<br/>
                                    Department of Food and Hospitality Management<br/>
                                    College of Nursing &#38; Health Professions,<br/>
                                    Drexel University,<br/>
                                    Philadelphia, Pennsylvania-19104, USA<br/>
                                    Email ID: jdeutsch@drexel.edu<br/>
                                    <a target="_blank" href="https://drexel.edu/cnhp/faculty/profiles/DeutschJonathan/" style={{color: "blue"}, {textDecoration:"underline"}} >Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Dr. Satyam Tripathi (Editor)<br/>
                                    Yoga Therapy Consultant<br/>
                                    Director, Union Centre Pte Ltd<br/>
                                    Union Yoga Ayurveda, Beauty World,<br/>
                                    Mandarin Park Shop Houses,<br/>
                                    51 Chun Tin Road, 599625, Singapore<br/>
                                    Email ID: ayur.st@gmail.com<br/>
                                    <a target="_blank" href="https://www.unionyogaayurveda.com.sg/dt_staffs/dr-satyam-tripathi/" style={{color: "blue"}, {textDecoration:"underline"}}>Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Dr. Amarjeet Kaur (Editor)<br/>
                                    Scientist (Food &#38; Nutrition),<br/>
                                    College of Community Science,<br/>
                                    Punjab Agricultural University,<br/>
                                    Ludhiana-141004. Punjab, India<br/>
                                    E-mail: sekhonamarjeet@pau.edu<br/>
                                    <a target="_blank" href="https://www.pau.edu/cohs/index.php?_act=manageDepartments&#38;DO=viewFaculty&#38;intDepTitleID=5&#38;intLinkID=1&#38;strDepTitle=Faculty" style={{color: "blue"}, {textDecoration:"underline"}} >Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Dr. Vandana Sabharwal (Editor)<br/>
                                    Assistant Professor,<br/>
                                    Department of Food and Nutrition<br/>
                                    Institute of Home Economics, University of Delhi,<br/>
                                    F- 4 Hauz khas, New Delhi -110016<br/>
                                    E-mail: vandana.sabharwal@ihe.du.ac.in<br/>
                                    <a target="_blank" href="http://ihe.du.ac.in/wp-content/uploads/2022/03/Dr.-Vandana-Sabharwal-converted.pdf" style={{color: "blue"}, {textDecoration:"underline"}} >Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Dr. Manoj Kumar Jangid (Editor)<br/>
                                    Specialist Pediatrician,<br/>
                                    NMC Royal Hospital, Dubai Investment Park 1,<br/>
                                    Dubai, United Arab Emirates <br/>
                                    E-mail: manoj.jangid@nmc.ae<br/>
                                    <a target="_blank" href="https://nmc.ae/hospitals/dubai/nmc-royal-hospital-dip-16" style={{color: "blue"}, {textDecoration:"underline"}} >Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Dr. Narendra Chouhan (Editor)<br/>
                                    Specialist Physician,<br/>
                                    Anaesthesia and Critical Care<br/>
                                    SSMC, Abu Dhabi, United Arab Emirates<br/>
                                    E-mail:nchouhan@ssmc.ae<br/>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Ms. Megha Panchal (Editor)<br/>
                                    Yoga Instructor / Yoga Therapist<br/>
                                    Union Yoga Ayurveda, Beauty World,<br/>
                                    Mandarin Park Shop Houses,<br/>
                                    51 Chun Tin Road, 599625, Singapore<br/>
                                    E-mail: megha283@gmail.com<br/>
                                    <a target="_blank" href="https://www.yogasingapore.net/instructor-profile/" style={{color: "blue"}, {textDecoration:"underline"}}>Profile Link</a>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Ms. Sandra Thiemann-Muller (Editor)<br/>
                                    Ayurveda Therapist<br/>
                                    Union Yoga Ayurveda, Beauty World,<br/>
                                    Mandarin Park Shop Houses,<br/>
                                    51 Chun Tin Road, 599625, Singapore<br/>
                                    E-mail: sandra.thiemann@gmx.ch<br/>
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Ms. Anjana Singhal (Editor)<br/>
                                    Pharmacist<br/>
                                    Director - Subsist Pharma<br/>
                                    622 B, 12th A Road, Sardarpura,<br/>
                                    Jodhpur-342001, India<br/>
                                    Email ID: singhalanjana547@gmail.com
                                    </p>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <p style={{"text-align": "center"}}>Dr. R. Mahendran (Editor)<br/>
                                    Associate Professor and Head<br/>
                                    Centre of Excellence in Non-Thermal Processing<br/>
                                    National Institute of Food Technology, Entrepreneurship and Management (NIFTEM) - Thanjavur<br/>
(an Institute of National Importance; formerly Indian Institute of Food Processing Technology - IIFPT)<br/>
Ministry of Food Processing Industries, Government of India
<br/>
                                    Email ID: mahendran@iifpt.edu.in<br/>
                                    <a target="_blank" href="http://www.iifpt.edu.in/profile/mahendran.php" style={{color: "blue"}, {textDecoration:"underline"}}>Profile Link</a>

                                    </p>
                                </div>

                    </div>
                </div>
            </div>
        </div>

  )
}
