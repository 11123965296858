import React, {MouseEventHandler,useState} from 'react'
import { Link} from "react-router-dom";
import "../styles/styles.css";
import "../styles/navbar.css"
import "../styles/topHeader.css"




  

export default function Header(props) {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    // const [Title, setTitle] = useState("International Journal of Nutrition & Lifestyle");

    function activateMenuItem(){
        var btnContainer = document.getElementById("linksUL");
    
        // Get all buttons with class="btn" inside the container
        var btns = btnContainer.getElementsByClassName("nav-Link");
    
        // Loop through the buttons and add the active class to the current/clicked button
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function() {
                var current = document.getElementsByClassName("text-white");
        
                // If there's no active class
                if (current.length > 0) {
                current[0].className = current[0].className.replace(" text-white", "");
                }
        
                // Add the active class to the current/clicked button
                this.className += " text-white";
            });
            }
    }

    // window.onload = function() {
    //     let changedTitle=localStorage.getItem("name")
    //     setTitle(changedTitle)
    // }

    document.addEventListener("DOMContentLoaded", function(){
        activateMenuItem()
    });
    // const changeTitle=(heading)=>{
    //     // console.log("before", localStorage.getItem("name"))
    //     localStorage.setItem("name",heading)
    //     // console.log("after", localStorage.getItem("name"))
    // }


    return (<> 
                <header className="top-header p-0 m-0 w-100 " >
                    <div className='row w-100 p-0 m-0  bg-white'>
                    <div className=" site_information p-0 m-0 w-100  ">
                        <div className='srTopMenu w-100'>
                            <div className='col-md-3 col-sm-3'><img style={{"width":"40%"}} src="/img/logo.jpg" alt="image"/></div>
                            <div className='col-md-3 col-sm-3 changeIt'>ISSN: 2583-3499 (Online)</div>
                            <div className='col-md4-3 col-sm-3 changeIt'><a href="mailto:contact@researchthrive.com"><img src="/img/mail_icon.png" alt="#" /> contact@researchthrive.com</a></div>
                            <div className='col-md-3 col-sm-3 changeIt'><a href="tel:contact@researchthrive.com"><img src="/img/phone_icon.png" alt="#" /> +91 7737099220</a></div>
                        </div>
                    </div>
                    <nav className="navigation col-md-10 col-sm-12 repositioning" >
                        <div className='parallelogram '></div>
                        <div className="logo_responsive" ></div>
                        <div className="brand-name "> Researchthrive</div>
                        <button className="hamburger" onClick={() => {setIsNavExpanded(!isNavExpanded)}}>
                            {/* hamburger svg code... */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="white" className="bi bi-list" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </button>
                        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                            <ul id='linksUL'>
                                {/* <li onClick={()=>{setTitle("International Journal of Nutrition & Lifestyle");changeTitle("International Journal of Nutrition & Lifestyle");setIsNavExpanded(!isNavExpanded)}} key="1"><Link id="Link1" className="nav-Link text-white" to="/"> Home</Link></li>
                                <li onClick={()=>{setTitle("About Us");changeTitle("About Us");setIsNavExpanded(!isNavExpanded)}} key="2" ><Link id='Link2'  className="nav-Link " to="/about"> About Us</Link></li>
                                <li onClick={()=>{setTitle("Editorial Board");changeTitle("Editorial Board");setIsNavExpanded(!isNavExpanded)}} key="3" ><Link id='Link3'  className="nav-Link hoverThis" to="/editorialboard"  >Editorial Board</Link></li>
                                <li onClick={()=>{setTitle("Current Issue");changeTitle("Current Issue");setIsNavExpanded(!isNavExpanded)}} key="4"><Link id='Link4'  className="nav-Link " to="/currentissue"  > Current Issue</Link></li>
                                <li onClick={()=>{setTitle("Archives");changeTitle("Archives");setIsNavExpanded(!isNavExpanded)}} key="5"><Link id='Link5'  className="nav-Link " to="/archives"  > Archives</Link></li>
                                <li onClick={()=>{setTitle("Submit Article");changeTitle("Submit Article");setIsNavExpanded(!isNavExpanded)}} key="6"><Link id='Link6'  className="nav-Link " to="/submitarticle"  > Submit Article</Link></li>
                                <li onClick={()=>{setTitle("Author Guidelines");changeTitle("Author Guidelines");setIsNavExpanded(!isNavExpanded)}} key="7"><Link id='Link7'  className="nav-Link " to="/authorguidelines"  > Author Guidelines</Link></li>
                                <li onClick={()=>{setTitle("Contact Us");changeTitle("Contact Us");setIsNavExpanded(!isNavExpanded)}} key="8"><Link id='Link8'  className="nav-Link " to="/contactus"  > Contact Us</Link></li>
                                 */}
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="1"><Link id="Link1" className="nav-Link text-white" to="/"> Home</Link></li>
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="2" ><Link id='Link2'  className="nav-Link " to="/about"> About Us</Link></li>
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="9"><Link id='Link8'  className="nav-Link " to="/faq"  > FAQ's</Link></li>

                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="3" ><Link id='Link3'  className="nav-Link hoverThis" to="/editorialboard"  >Editorial Board</Link></li>
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="4"><Link id='Link4'  className="nav-Link " to="/currentissue"  > Current Issue</Link></li>
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="5"><Link id='Link5'  className="nav-Link " to="/archives"  > Archives</Link></li>
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="6"><Link id='Link6'  className="nav-Link " to="/submitarticle"  > Submit Article</Link></li>
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="7"><Link id='Link7'  className="nav-Link " to="/authorguidelines"  > Author Guidelines</Link></li>
                                <li onClick={()=>{setIsNavExpanded(!isNavExpanded)}} key="8"><Link id='Link8'  className="nav-Link " to="/contactus"  > Contact Us</Link></li>

                            </ul>
                        </div>
                    </nav>
                    </div>
                </header>

                {/* <!-- Start Banner --> */}
                {/* <div className="section inner_page_banner removeSpace" style={{backgroundColor:"#ff8436"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="banner_title">
                                    <h3>{Title}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <!-- End Banner --> */}
            </>
        
    
      
  )
}

