// import React from 'react'

// export default function footer() {
//   return (
//     <div>
//         <div className="footer_bottom" style={{backgroundColor:"#ff8436"}}>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-12">
//                         {/* <p className="crp"> © Copyright 2023 All rights reserved</p> */}
//                         <p className="crp"> © Copyright 2023 All rights reserved</p>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//   )
// }
import React from 'react';

export default function Footer() {
    const currentYear = new Date().getFullYear();

  return (
    <div>
      <div className="footer_bottom" style={{ backgroundColor: "#ff8436" }}>
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ textAlign: 'center', marginBottom: '10px' }}>
              <img src={"/sponser.png"} alt="QTanalyics Logo" style={{ maxHeight: '50px', marginRight: '10px' }} />
              <img src={"/Crossref logo.png"} alt="Crossref Logo" style={{ maxHeight: '50px' }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className="crp">© Copyright {currentYear} All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
