import React from 'react'
import EndPageBanner from './endPageBanner'

export default function about(props) {
  return (

    <div>
      {/* <!-- Start Banner --> */}
      <div className="section inner_page_banner removeSpace" style={{backgroundColor:"#ff8436"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="banner_title">
                                    <h3>About Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Banner --> */}
      <div className="section layout_padding about_bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">

                    <div className="full paddding_left_15" style={{textAlign: "justify"},{textJustify: "inter-word"}}>
                    <p>
                            International Journal of Nutrition &amp; Lifestyle ensures the scrutinized publication of
                            high-quality research papers.
                            It’s a scientific research journal devoted to original articles based on Experimental /
                            Community / Clinical Nutrition &amp; Lifestyle related research or practices that contribute to
                            holistic Health &amp; Wellness of human beings.<br/>

                            <b>Language of Journal:</b> English<br/>
                            <b>Starting Year:</b> 2021<br/>
                            <b>Format of Publication:</b> Online Only<br/>
                            <b>Frequency of Publication:</b> Peer-reviewed quarterly Online journal
                                                        <br/>
                                                        <b>Aim:</b>
                                                        <br/>
                            The prime objective of the International Journal of Nutrition &amp; Lifestyle is to provide a platform to highlight quality research by eminent
                            researchers in the field of Nutrition and Lifestyle contributing to Holistic Health
                            and Wellness. We aim to facilitate students &amp; researchers from academic &amp;
                            clinical field to share &amp; publish their knowledge &amp; scientifically proven practises
                            with fellow researchers as well as policy makers
                                                        <br/>
                            <b>Scope:</b>
                                                        <br/>
                            1. The scope covers all researches related to holistic wellness that includes
                            physical, mental, social, emotional, spiritual wellness of a human being leading to
                            a higher Happiness Quotient.
                            <br/>
                            2. The Journal covers all types of research work in the field of Dietary practices /
                            guidelines, Diet and Disease, Dietary Surveys, Clinical Nutritional Interventions,
                            Public Health Nutrition, Nutrigenomics, Molecular Nutrition, Food Processing
                            and Technology, New Product Development, Value Added Food Products,
                            Nutritional Epidemiology, Weight Management, Organic & Agri-based Food Researches, Food Biotechnology &
                            all relevant Health based studies.
                                                        <br/>
                            3. Lifestyle based researches including Yoga, Exercise, Herbal medicinal
                            researches, et. al. are also included
                        </p>
                    </div>

                </div>
                <div className="col-md-6">
                    <div className="full text_align_right_img">

                  </div>
                </div>
            </div>
        </div>
      </div>
      <EndPageBanner/>
    </div>
  )
}
