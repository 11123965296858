import React from 'react'

export default function guidelines() {
  return (
    <div>
        <div className="section inner_page_banner" style={{backgroundColor:"#ff8436"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner_title">
                        <h3>Author Guidelines</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <div className="section layout_padding about_bg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">

                    <div className="full paddding_left_15" style={{"textAlign": "justify"}, {"textJustify": "inter-word"}}>
                        <p><b>1. General guidelines</b>
Articles are accepted on the strict understanding that the material in whole or in part has
not been, nor is being, considered for publication elsewhere.
                            <br/><b>2. Ethical guidelines</b>
                            <br/><b>1. Avoiding Plagiarism:</b> The journal may use plagiarism detection software to
screen the submissions. If plagiarism is identified, the research article will be
rejected.
                            <br/><b>2. Authorship consent:</b> Corresponding / Submitting author is believed to have had
taken prior consent from all authors before submitting a manuscript to the
Journal.
                            <br/><b>3. Acknowledgements:</b> Under acknowledgements please mention contributors to
the article other than the authors. Sources of research material should be named
along with their location and duly credited.
                            <br/><b>4. Conflict of Interest:</b> Authors are requested to disclose any possible / applicable
conflict of interest that may or may not arise
                            <br/><b>5. Funding source:</b> Authors are requested to mention the source of funding for their
research while submitting a paper.
                            <br/><b>6. Appeal of Decision:</b> The Editor’s decision on a paper shall be final &amp; binding to all
and cannot be appealed.
                            <br/><b>7. Permissions:</b> If all or parts of previously published illustrations are used,
permission must be obtained from the concerned copyright holder(s).
                            <br/><b>8. Copyright:</b> If the manuscript is accepted, the corresponding / submitting author is
required to email a copyright consent to the Journal on behalf of all authors on
the paper.
                            <br/><b>3. Manuscript preparation guidelines:</b>
                            <br/><b>1. Type of Manuscript:</b> While preparing a manuscript, authors should refer to
manuscript preparation guidelines. PrGenerally, we need to adhere to the
                            following guidelines: <br/><b>a) Original Papers:</b> The paper is generally about 5000 words
including tables, figures, references. Typically a table or figure is equivalent to
150 words while a photograph is equivalent to 300 words. The Paper should
include: Summary (fewer than 150 words) containing the main results and

conclusions; up to 10 keywords that accurately identify the paper&#39;s subject,
purpose and focus; an Introduction with clear objectives. Materials and Methods
with experimental detail, sources of material must be given and statistical
methods must be specified by reference, unless non-standard. Results should be
presented properly using well-designed tables and/or figures; data should not be
repeated in tables &amp; figures, appropriate statistical data should be given. If the
editor finds inadequate mathematical treatment of the data, the manuscript will
be returned un-refereed. Discussion should cover the implications and
consequences and the conclusions should be concise; brief Acknowledgements /
References.
<br/><b>b) Review Articles:</b> (fewer than 5000 words) These are concise, critical but
constructive and conclusive written for non-specialists.
<br/><b>2. Manuscript format &amp; structure:</b>
                            <br/><b>a) The language of publication is English.</b> Pls. ensure your paper is clearly
written in standard, scientific English language appropriate to your discipline.
                            <br/><b>b) Standard Usage, Abbreviations and Units:</b> Spelling and hyphenation should
conform to The Concise Oxford English Dictionary. Statistics and measurements
should always be given in figures, e.g. 10 min., except when the number begins
with a sentence. When the number does not refer to a unit of measurement it
should be spelt in full unless it is 100 or greater.
Abbreviations should be used sparingly and only if a lengthy name or expression
is repeated throughout the manuscript, and never in the title. The abbreviated
name or expression should be cited in full at first usage, followed by the accepted
abbreviation in parentheses.
                            <br/><b>c) Metric SI units should generally be used</b>
                            <br/><b>d) Main Text:</b> Text files should be formatted double-spaced with no hyphenation
                            <br/><b>e) Structure:</b> All manuscripts should include
<br/><b>•</b> Title Page: The title page should carry an informative title that reflects the
content, the names of the authors, and the place<br/>(s) where the work was
carried out. The full postal address plus email address of the indicated
corresponding author must be given. Up to 10 relevant keywords to aid
data retrieval and indexing. Title should be in font Arial, size 16 bold.
Author’s names should be in 12 bold with an estrics on corresponding
author’s name. Affiliation, address &amp; email id should be written in Arial, 12
italics. Other parts of the manuscript should be in arial 12 unbold, just
headings should be in 14 Arial, bold &amp; subheadings in 12 Arial bold.
References should be written in 10 Arial
<br/><b>•</b> Summary (or Abstract), used in the Paper / Review: It should include an
introduction of one or two sentences followed by comparative data

between treatments where interesting effects were observed, followed by
conclusion.
<br/><b>•</b> Statistical Methods: Statistical methods used should be defined well
supported with references
<br/><b>•</b> References follow the Harvard system: References in the text should be
cited by the authors&#39; names followed by year of publication, unless there
are three or more authors when only the first author&#39;s name is quoted
followed by et al. e.g. Smith et al. (1999) or Jones and Smith (2000). Add
a, b, c etc. to distinguish between two or more references with the same
author name and year date (e.g. Jones 1999a,b). At the end of the paper
references should be listed in alphabetical order with the title of the article
or book and the title of the journal given in full, as shown:
Gupta, V. &amp; Nagar. R. (2008). Physico - Chemical and acceptability of rabadi (a
fermented soya flour product) as affected by cooking and fermentation time.
International Journal of Food Science and Technology, 49, 939 - 943.
Hawk, B. P., Oser L. B. &amp; Summerson, H.W. (1968). Physiological Chemistry.
Pp. 644, 1214. NewYork, NY: McGraw Hill Book Co.
Unpublished work must only be cited where necessary, and only in the text. It is
essential that all citations and references are carefully checked before submission, as
mistakes may cause delays.
References to material on the World Wide Web can be given as following:
Beckleheimer, J. (1994). Online reference included in article [Internet document]
URLhttp://www.sample_url.bibliography/html. Accessed 01/04/2004.
<br/><b>•</b> Tables, Figures and Figure Legends: Each table &amp; figure should have proper title
&amp; cited in the text with table no. &amp; figure no. Axes on graphs should always be
titled and include units where appropriate.
<br/><b>•</b> Ethical Guidelines: Please give a statement describing any ethical guidelines
under which your research was carried out. Where ethics approval was not
required a simple statement that “Ethics approval was not required for this
research.” is sufficient. This should be placed below the Acknowledgements
section.
                            <br/><b>•</b> Conflict of Interest: You must provide a brief statement describing any potential
conflict of interest in the work you are reporting here. If there is no conflict of
interest, a simple statement to that effect is sufficient.
<br/><b>•</b> Author Response: Please include a detailed point by point response to the
decision letter with your re-submission. In order to help editors and reviewers
focus on your edits please highlight key changes.
AFTER ACCEPTANCE
<br/><b>•</b> Upon acceptance, the manuscript will be forwarded to the Production Editor who
is responsible for the production of the journal

<br/><b>•</b> Before publication, corresponding author will receive article for proof corrections
<br/><b>•</b> Proofs must be returned to the Production Editor within three days of receipt
<br/><b>•</b> Each article will be given a DOI at the time of publication</p>
                    </div>

                </div>
                {/* <div className="col-md-6">
                   <div className="full text_align_right_img">
                        <!--<img src="images/img3.png"  alt="#" />-->
                    </div>
                </div> */}
            </div>
        </div>
    </div>


    </div>
  )
}
