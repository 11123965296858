import React, { useRef } from 'react'
import EndPageBanner from './endPageBanner'
import emailjs from '@emailjs/browser';


export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zuu1hqr', 'template_b0012g5', form.current, 'NULJddS_FPq-yhMQQ')
      .then((result) => {
          console.log(result.text);
          alert("message sent successfully")
      }, (error) => {
          console.log(error.text);
          alert("Error")

      });
    
  };

  return (
    <div>
      <div className="section inner_page_banner" style={{backgroundColor:"#ff8436"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner_title">
                        <h3>Contact Us</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <div class="section contact_form">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-sm-12 offset-lg-3">
              <div class="full">
                <form class="contact_form_inner" action="#" ref={form} onSubmit={sendEmail}>
                    <fieldset>
                        <div class="field">
                            <input type="text" name="name" placeholder="Your name" />
                        </div>
                        <div class="field">
                            <input type="email" name="email" placeholder="Email" />
                        </div>
                        <div class="field">
                            <input type="number" name="phone_no" placeholder="Phone number" />
                        </div>
                        <div class="field">
                            <textarea placeholder="Message" name='message'></textarea>
                        </div>
                        <div class="field center">
                        <input type="submit" value="Send" />
                        </div>
                    </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EndPageBanner/>
    </div>
  )
}
