import React from 'react'

export default function endPageBanner() {
  return (
    <>
      <div className="row w-100 p-0 m-0" >
        <div className="column w-50" align="center" ><hr/>Editor-in-Chief:<br/>
          Dr. Vineeta Bansal (Ph.D., R.D.)<br/>
          Associate Professor - Clinical Nutrition and Dietetics<br/>
          Mahatma Gandhi Medical College &#38; Hospital,<br/>
          Sitapura Industrial Area, Jaipur-302022, Rajasthan, India.<br/>
          R.D. No. 562018<br/>
          Email ID: drvineetabansal@mgumst.org<br/>
        </div>
        <div className="column w-50" align="center" >
          <hr/> Publisher: <br/>
          Shailesh Bansal<br/>
          Researchthrive Publications<br/>
          81, Bhrigu Nagar,Ajmer road<br/>
          Jaipur-302021, Rajasthan, India<br/>
          Contact: +91-7737099220<br/>
          Email: contact@researchthrive.com<br/>
        </div>
      </div>
    </>
  )}
