// import React from 'react';
// import { useParams, Link } from 'react-router-dom';

// function ArticleDetails({ items }) {
//   let { articleId } = useParams();
//   const article = items.find(item => item.sNo === articleId);

//   return (
//     <div className="container">
//       <div className="section layout_padding">
//         <h2>{article.title}</h2>
//         <h4>By {article.author}</h4>
//         <p>{article.shortDesc}</p>
//         <div>
//           <a href={article.fullArticle} target="_blank" rel="noopener noreferrer">Read Full Article</a>
//         </div>
//         <Link to="/currentissue">Back to Current Issue</Link>
//       </div>
//     </div>
//   );
// }

// export default ArticleDetails;
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import  Footer from './footer';
function ArticleDetails({ items }) {
  let { articleId } = useParams();
  const article = items.find(item => item.sNo === articleId);

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <>
    
      <div className="section inner_page_banner" style={{ backgroundColor: "#ff8436" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title">
                <h3>{article.title}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section layout_padding">
          <div className="card" style={{ margin: "20px 0" }}>
            <div className="card-body">
              <h4>Author: {article.author}</h4>
              <p>{article.shortDesc}</p>
              
              <a href={article.fullArticle} target="_blank" rel="noopener noreferrer" className="btn btn-primary my-4">Read Full Article</a>
              <iframe
                src={article.fullArticle}
                title="Article PDF"
                width="100%"
                height="600px"
                style={{ border: "none" }}
              >
                This browser does not support PDFs. Please download the PDF to view it: <a href={article.fullArticle}>Download PDF</a>.
              </iframe>
              <div className="mt-3">
                <Link to="/currentissue">Back to Current Issue</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ArticleDetails;
